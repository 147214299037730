/*
--------------
HERO BANNER SECTION
--------------
*/

.banner { position: relative; 
  &--home { margin-top: 126px; padding: rem-calc(60 20); background-color: white; box-shadow: 0 2px 22px 8px rgba(0,0,0,0.06); overflow: hidden;

    .video-fullscreen { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; transform: translateX( calc(100vw + 619px) ); transition: all 1s ease-in-out; z-index: 2;
      &::before { pointer-events: none; content: ""; position: absolute; bottom: 0; left: 0; width: 0; height: 0; border-style: solid; border-width: 0 0 619px 840px; border-color: transparent transparent #000000 transparent; transform: translateX(-100%); z-index: 0; }
      #videoplayer { width: 100%; height: calc(100% - 50px); margin-top: 50px; opacity: 0; transition: all .3s ease-in-out; transition-delay: 1s; }
      .close-button {
        span { font-size: 50px; }
      }
    }

    &__text { 
      h2 { text-transform: uppercase; font-size: rem-calc(40); font-weight: bold; line-height: 1;
        @include breakpoint(medium) { font-size: rem-calc(60); }
        @include breakpoint(large) { font-size: rem-calc(80); }
        strong { display: block; color: $secondary-color; }
      }
    } // .banner--home__text
    &__card {
      figure { position: relative; max-width: 426px; margin-left: auto; margin-bottom: 2rem; box-shadow: 15px 15px 0 0 #101010;;
        &::after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.3); }
        img { width: 100%; height: 426px; object-fit: cover; }
        figcaption { position: absolute; bottom: 20px; left: 30px; font-size: rem-calc(30); font-weight: bold; color: white; z-index: 1; 
          span { display: block; font-size: rem-calc(20); font-weight: 400; }
        }
      }
      p a { color: #FFF; }
    } // .banner--home__card

    .grid-container { position: relative; z-index: 1; }
    &::after { content: ""; position: absolute; bottom: 0; right: 0; width: 0; height: 0; border-style: solid; border-width: 0 0 587px 840px; border-color: transparent transparent #000000 transparent; z-index: 0; }

    &.video-active {
      .video-fullscreen { transform: translateX(0); 
        #videoplayer { opacity: 1; }
      }
    }

    @include breakpoint(1340 down) {
      .video-fullscreen::before {  content: none; }
    }

  } // .banner--home

  &--case { position: relative; margin-top: 90px; padding-bottom: 50px; background-color: #000; 
    @include breakpoint(large) { margin-top: 95px; }

    &__background { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;
      img { width: 100%; height: 100%; object-fit: cover; }
      &::after { content: ""; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); }
    }
    &__text { position: relative; text-align: center; color: #fff; z-index: 1; 
      h2 { margin-bottom: 0; text-transform: uppercase; font-size: rem-calc(30); font-weight: bold; }
      h3 { font-size: rem-calc(20); font-weight: bold; }

      &--button-box { position: relative; height: 150px; }
    }
    &__arrow { position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%); transition: all .3s ease-in-out; z-index: 1;
      &:hover { transform: translateX(-50%) translateY(5px); }
    }

    .grid-container { height: calc(100vh - 180px); min-height: 300px; display: flex; align-items: center; justify-content: center; 
      @include breakpoint(640px) { height: calc(100vh - 135px); }
    }
  }

  &--institutional { position: relative; margin-top: 90px; padding-bottom: 50px; background-color: #000; 
    @include breakpoint(large) { margin-top: 95px; }

    &__background { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;
      img { width: 100%; height: 100%; object-fit: cover; }
      &::after { content: ""; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); }
    }
    &__text { position: relative; width: 550px; margin: 1rem auto 0; text-align: center; color: #fff; z-index: 1; 
      h2 { margin-bottom: rem-calc(40);  text-transform: uppercase; font-size: rem-calc(30); font-weight: bold; color: $secondary-color; 
        @include breakpoint(medium down) { margin-bottom: rem-calc(20); }
      }
      h3 { font-size: rem-calc(30); font-weight: bold; 
        @include breakpoint(medium down) { font-size: rem-calc(22); }
      }
    }
    &__arrow { position: absolute; bottom: 0; left: 50%; transform: translateY(-50%); transition: all .3s ease-in-out; z-index: 1;
      &:hover { transform: translateY(-50%) translateY(5px); }
    }

    .grid-container { height: calc(100vh - 180px); min-height: 300px; display: flex; align-items: center; justify-content: center; 
      @include breakpoint(640px) { height: calc(100vh - 135px); }
    }
  }

  &--contact { margin-top: 90px;
    @include breakpoint(large) { margin-top: 95px; }
    
    &__background { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;
      img { width: 100%; height: 100%; object-fit: cover; }
      &::after { content: ""; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); }
    }

    &__text { position: relative; 
      @include breakpoint(large) { margin-top: rem-calc(250); }
      p { font-size: rem-calc(100); font-weight: bold; color: white; 
        @include breakpoint(medium down) { padding-top: rem-calc(50); } 
      }
      .button-group { margin-top: rem-calc(60); margin-bottom: rem-calc(60); flex-wrap: wrap; }
      .button { display: flex; align-items: center; min-width: 182px; padding: rem-calc(15 30); margin: rem-calc(10); background-color: #FFF; border-radius: 10px; text-align: left; font-weight: 400; color: #000; line-height: 1.5; }
    }
  }

} // .banner