/*
--------------
ELEMENTS e CLASSES
--------------
*/


/* ------ ELEMENTS ------- */
*, *::before, *::after { box-sizing: border-box; }
body { overflow-x: hidden; }

  // utilities
  .mt-0 { margin-top: 0 !important; }
  .mt-1 { margin-top: 1rem !important; }
  .mt-2 { margin-top: 2rem !important; }
  .mt-3 { margin-top: 3rem !important; }
  .mb-0 { margin-bottom: 0 !important; }
  .mb-1 { margin-bottom: 1rem !important; }
  .mb-2 { margin-bottom: 2rem !important; }
  .mb-3 { margin-bottom: 3rem !important; }
  .pt-1 { padding-top: 1rem !important; }
  .pt-2 { padding-top: 2rem !important; }
  .pt-3 { padding-top: 3rem !important; }
  .pb-1 { padding-bottom: 1rem !important; }
  .pb-2 { padding-bottom: 2rem !important; }
  .pb-3 { padding-bottom: 3rem !important; }
  .rotate-90 { transform: rotateZ(90deg); }
  

  // links
  a, .button { transition: all .3s ease-in-out; }
  a.link-arrow { position: relative; margin-right: 48px;
    &::after { content: url("../img/green_arrow.svg"); position: absolute; top: 50%; right: -2rem; height: 100%; transform: translateY(-50%); vertical-align: sub; transition: all .3s ease-in-out; }
    &:hover::after { transform: translateY(-50%) translateX(4px); }
  }
  a.link-circle-arrow { position: relative; margin-right: 46px;
    &::after { content: url("../img/arrow-circle.svg"); position: absolute; top: -2px; right: -40px; width: 29px; height: 29px; transition: all .3s ease-in-out; } 
    &:hover::after { transform: scaleX(1.1) scaleY(1.1); }
  }

  // forms
  [type='text'], [type='tel'], [type='email'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea { border-radius: 5px; border: 1px #979797 solid; 
    &:focus { border: 1px $dark-gray solid; }
  }
  [name='contact_phone'] { display: none; }
  .is-invalid-input:not(:focus) { background: transparent; }
  textarea { padding: 18px;
    &, &:focus { border-width: 1px; border-radius: 0; }
  }
  .form-error { margin-bottom: 2 * $global-padding; }
  form .dialog-box { margin-bottom: 2rem; padding: rem-calc(15); border-radius: 10px; background-color: #000; text-align: center; color: #fff; 
    &__title { font-size: rem-calc(22); font-weight: bold; }
    &.error { background-color: $warning-color; }
    &.success { background-color: $success-color; }
  }


  // swiper
  .swiper-button-disabled { opacity: 0 !important; }
  .swiper-button-next, .swiper-button-prev { background-size: 16px 30px; }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev { background-image: url("../img/next-arrow.svg"); right: 0; }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next { background-image: url("../img/next-arrow.svg"); transform: rotateY(180deg); left: 0; }

/* ------- CLASSES ------- */
  // buttons
  .button { font-weight: bold;
    &:hover { box-shadow: 0 9px 17px 0 rgba(0,0,0,0.08); transform: translate3d(0,-5%,0); }
    
    &-social { width: 30px; height: 30px; padding: 4px !important; border: 1px solid $primary-color; border-radius: 50%; text-align: center; 
      &:hover { box-shadow: 0 9px 17px 0 rgba(0,0,0,0.08); transform: translate3d(0,-5%,0); border-color: $secondary-color; background-color: $secondary-color; }
    }

    &.arrow { position: relative; padding-right: rem-calc(40);
      &::after { content: url("../img/green_arrow.svg"); position: absolute; top: 50%; right: rem-calc(12); transform: translateY(-50%); vertical-align: sub; transition: all .3s ease-in-out; }
      &:hover::after { transform: translateY(-50%) translateX(4px); }
    }
    
  } // .buttons

  // Play Button positioning for videos
  .play-button { @include absolute-center; z-index: 2; 
    svg, img { transition: all .3s ease-in-out; }
    &:hover {
      svg, img { transform: scaleX(1.1) scaleY(1.1); }
    }
    &:focus { outline: none; 
      svg, img { transform: scaleX(1.1) scaleY(1.1); }
    }
  } // .play-button

  // cards
  .card { position: relative; z-index: 1; padding: $card-padding; }
  
  // end-of-content line
  .end-of-content::after { content: ""; width: 421px; max-width: 100%; height: 1px; margin: rem-calc(50) auto; display: block; background-color: #979797; }

/* -------  HEADER ------- */
.page-header { padding-top: rem-calc(42); padding-bottom: rem-calc(23);
  h1 img { vertical-align: text-top; }
  h2 img { vertical-align: baseline; }
  ul.menu { margin-top: 1rem; align-items: center; 
    @include breakpoint(medium) { margin-top: 0; }
    .button { margin-right: 1rem; padding: rem-calc(5 34); font-size: rem-calc(18); font-weight: 400; }
    .button-social { margin-right: rem-calc(10); }
  }
  &__menu { text-align: right;
    .close-button { color: #000; 
      &:hover { color: $secondary-color; } 
    }
    .hamburger { @include hamburger($secondary-color, $primary-color, 35px, 24px, 4px, 3); }

    @include breakpoint(large) { text-align: left; }
    .menu.dropdown { 

      @media screen and (min-width: 1024px) and (max-width: 1028px) {
        & > li > a { padding: 0.7rem; }
        & > li.is-dropdown-submenu-parent > a { padding-right: 1.5rem; }
      }
      
      & > li.opens-right > .is-dropdown-submenu { top:  calc(100% - 20px);
        @include breakpoint(medium) { top: 100%; }
        @include breakpoint(large) { top: calc(100% + 23px); }
      }
      li.is-submenu-item.is-dropdown-submenu-item a { padding: 0.7rem; color: #fff; 
        &:hover { color: $secondary-color; }
        @include breakpoint(large) { padding: 0.7rem 1rem; }
      }
      a::after { transition: all .3s ease-in-out; }
      a:hover { color: $secondary-color; 
        &::after { border-color: $secondary-color transparent transparent; }
      }
      @include breakpoint(medium down) { flex-direction: column; align-items: flex-start; justify-content: flex-start; width: 100%; margin-top: rem-calc(50); padding: 0 .5rem;

        & > li { display: block; width: 100%; text-align: left; 
          ul { position: static; margin-top: 0; margin-left: 1rem; background-color: transparent; border: none; 
            li.is-submenu-item.is-dropdown-submenu-item a { color: #000; 
              &:hover { color: $secondary-color; }
              &::before { content: "› "; }
            }
          }
        }

      }
    }
  }
  &__contact-menu {
    @include breakpoint(medium down) { justify-content: center; align-items: center; max-height: 100px; width: 100%;
      .button { display: block; }
      .contact-button { display: block; width: 100%; 
        a { width: 100%; margin-left: 1rem; }
      }
    }
  }
  &.fixed { position: fixed; top: 0; left: 0; width: 100%; background-color: #FFF; z-index: 10; }
  &.box-shadow { box-shadow: 0 2px 22px 8px rgba(0,0,0,.06); }
}

/* -------  TITLE ------- */
.page-title { position: relative; margin-top: 90px; padding: 40px 0 0;
  @include breakpoint(large) { margin-top: 95px; padding: 100px 0; } 

  h1 { text-align: center; font-size: rem-calc(40); font-weight: bold; line-height: 1; }
}

/* -------  FOOTER ------- */
.page-footer { position: relative; padding: rem-calc(40); background-color: black; color: white;
  &::before { position: absolute; top: 0; left: 0; width: 45%; height: 7px; display: block; background-color: #81e636; }
  a { color: white; 
    &:hover { color: $secondary-color; }
  }
  ul { margin-bottom: 2rem;
    li { line-height: 2; }
  }

  &__brand { max-width: 371px; margin: rem-calc(50) auto; 
    &-image { margin-bottom: .6rem; }
    &-text { line-height: 1.5; }
  }
}

/* -------  Reveal - Video Modal ------- */
.reveal {
  iframe { max-width: 100%; max-height: 100%; width: 100vw; height: 80vh; }
}

/* -------  Juicer - Instagram Feed ------- */
.section--instagram {
  h1.referral, li.feed-item.juicer.image-post.juicer-ad, a.j-paginate.juicer-button { display: none !important; }
}

/*
--------------
PAGE SECTIONS
--------------
*/

.section {
  &--address { padding: 2rem 1rem;
    .flex-container { padding: rem-calc(30); flex-wrap: wrap-reverse; }

    &__title { margin-bottom: 3rem; text-align: center; font-size: 2rem; } // close .section--address__title
    &__location {
      &--figure { position: relative;
        img { width: 100%; }
        figcaption { @include absolute-center; text-transform: uppercase; text-align: center; font-size: rem-calc(40); color: white; line-height: 1.3; z-index: 1; }
        &::after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.3); }
      } // close .section--address__location--figure
      &--info {
        p { margin-bottom: 0; color: $medium-gray; }
        &-link { margin: 1rem 0; font-weight: bold; 
          a { position: relative; color: $secondary-color; 
            &:hover { color: $primary-color; }
          }
        } // close .section--address__location--info-link
      } // close .section--address__location--info
      &--phone { 
        a { font-size: rem-calc(20); font-weight: bold; color: $medium-gray; 
          &:hover { color: $secondary-color; }
        }
      }
    } // close .section--address__location
  } // close .section--address

  &--services { margin: 50px 20px; padding: 70px 0 0; overflow: hidden;
    &__title { text-align: center; text-transform: uppercase; font-size: rem-calc(23); font-weight: bold; color: $secondary-color; 
      @include breakpoint(medium) { font-size: rem-calc(32); }
      &::after { content: ""; width: 23px; height: 6px; margin: 4px auto; display: block; background-color: $secondary-color }
    } // close .section--services__title
    &__block { margin: rem-calc(50) auto rem-calc(70); max-width: 830px;
      &--image { z-index: 1;
        figure { position: relative; text-align: center;
          span { position: absolute; transition: all .5s ease-in-out; transition-delay: 1s; transform: translateX(-100px); opacity: 0;
            &.is-active { transform: translateX(0); opacity: 1; }
          }
        }
        &.medium-order-2 {
          figure span { transform: translateX(100px); 
            &.is-active { transform: translateX(0); }
          }
        }
        // &:hover { 
        //   figure span { transform: scaleX(1.05) scaleY(1.05); }
        // }
      } // close .section--services__block--image
      &--content {
        @include breakpoint(medium down) { margin-top: 1rem; }
        &-title { margin-top: rem-calc(30); display: flex; align-items: center; font-size: rem-calc(20); font-weight: bold; 
          @include breakpoint(medium) { margin-top: 0; font-size: rem-calc(25); }
          @include breakpoint(large) { margin-top: rem-calc(70) }
          &__link a { display: block; }
        } // close .section--services__block--content-title
        &-idea { position: relative; max-width: 325px; color: $medium-gray; 
          @include breakpoint(large) { margin-top: rem-calc(90); }

          &__title { font-size: rem-calc(18); font-weight: bold; }
          p { margin-bottom: 0; line-height: 1.5; }

          &::before { content: ""; display: block; position: absolute; top: 13px; left: -410px; width: 400px; height: 1px; background-color: $medium-gray; z-index: 0; }
        } // close .section--services__block--content-idea
      } // close .section--services__block--content

      &.block {
        &-ativacao {
          .section--services__block {
            &--image { 
              span { right: 0; bottom: -40px; left: auto; 
                @include breakpoint(medium) { right: -35px; bottom: -40px; left: 100px; }
              }
            }
            &--content-idea {
              @include breakpoint(medium) { margin-left: 20px; }
            }
          }
        } // close .block-ativacao
        &-endomarketing {
          .section--services__block {
            &--image { 
              span { right: 0; bottom: -1px; left: 0; 
                @include breakpoint(medium) { right: -10px; bottom: -3px; left: -5px; }
                @include breakpoint(large) { left: -60px;
                  img { max-width: 110%;  }
                }
              }
            }
            &--content-idea {
              @include breakpoint(medium) { margin-left: 50px; } 
              
            }
            &--content.medium-order-1 { 
              @include breakpoint(medium) { padding-right: 40px; } 
            }
          }
        } // close .block-endomarketing
        &-eventos {
          .section--services__block {
            &--image { 
              span { right: 0px; bottom: -40px; left: 150px; 
                @include breakpoint(medium) { right: -55px; bottom: -40px; left: 200px; }
              }
            }
          }
        } // close .block-eventos
        &-convencao {
          .section--services__block {
            &--image { 
              span { right: auto; bottom: -22px; left: 0px; 
                @include breakpoint(medium) { right: 220px; bottom: -22px; left: -40px;  }
              }
            }
            &--content.medium-order-1 { 
              @include breakpoint(medium) { padding-right: 40px; } 
            }
          }
        } // close .block-convencao
        &-branding {
          .section--services__block {
            &--image { 
              span { right: auto; bottom: -60px; left: 0px; 
                @include breakpoint(medium) { right: 80px; bottom: -60px; left: -240px;  }
              }
            }
          }
        } // close .block-branding
      }

    } // close .section--services__block

    &.services-page { margin: 0; padding: 0;
      .section--services__block { margin-top: -3rem; padding-top: 6rem; 
        &--content {
          @include breakpoint(medium) {
            &:not(.medium-order-1) { padding-left: 2rem; }
          } 
          &-title__link { position: relative; margin-left: rem-calc(28); font-size: rem-calc(15); font-weight: 400; 
            a { color: $secondary-color; }
          }
        }
      }
    } // close .services-page
  } // close .section--services

  &--carousel { position: relative; padding: rem-calc(70 32);
    &::before { content: ""; position: absolute; top: 0; left: 0; width: 60%; height: 50%; display: block; background-color: $secondary-color; transform: skewY(2deg); }
    &::after { content: ""; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; z-index: 0; transform: skewY(-2deg); }

    &__title { text-align: center; font-size: rem-calc(45); font-weight: bold; color: #fff; }

    .grid-container { position: relative; z-index: 2; }
    .swiper-container { padding-top: rem-calc(50); padding-bottom: rem-calc(50);
      .swiper-wrapper { align-items: center; 
        .swiper-slide { display: flex; justify-content: center; }
      }
    } // close .swiper-container

    &__clients {
      .section--carousel__title { color: $secondary-color; }
      .swiper-slide a {
        .svg-color { transition: all .3s ease-in-out; }
        &:hover .svg-color { fill: #fff; }
      }
    } // close .section--carousel__clients
  } // close .section--carousel

  &--instagram { margin: rem-calc(100 0);
    &__title { margin-bottom: rem-calc(45); font-size: rem-calc(45); font-weight: bold; text-align: center; line-height: 1;
      strong { color: $secondary-color; }
    }
    &__link { margin: rem-calc(30); text-align: center;
      a { text-decoration: underline; }
    }
  }

  &--case-intro { padding-top: rem-calc(80);
    @include breakpoint(medium) { padding-top: rem-calc(100); }
    &__text { padding: rem-calc(30); font-size: rem-calc(18);
      @include breakpoint(medium only) { align-self: center; }
      @include breakpoint(medium) { padding: rem-calc(0 30); }
      a { color: $secondary-color; }
    }
    &__card { position: relative; padding: rem-calc(40); box-shadow: 0 2px 6px 0 rgba(0,0,0,.3); background-color: #FFF; 
      @include breakpoint(large) { margin-top: rem-calc(-130); 
        p { max-width: 350px; }
      }

      h3 { margin-bottom: rem-calc(20); font-size: rem-calc(25); font-weight: bold; }

      &--tag { display: inline-block; margin-bottom: rem-calc(30); padding: rem-calc(0 38); border-radius: 5px; background-color: black; font-size: rem-calc(18); color: $secondary-color; }
      &--button {
        @include breakpoint(large) { position: absolute; bottom: -33px; right: 22px; }
      }
    }
  }

  &--content { margin: rem-calc(100) auto;
    &__text { max-width: 630px; margin: rem-calc(32) auto; 
      @include breakpoint(medium) { margin: rem-calc(70) auto; }

      h2 { margin-bottom: rem-calc(22); font-size: rem-calc(25); font-weight: bold; text-transform: uppercase; 
        @include breakpoint(medium) { font-size: rem-calc(30); }
      }
      h3 { margin: 4rem 0; font-size: rem-calc(25); font-weight: bold; text-align: center; 
        @include breakpoint(medium) { font-size: rem-calc(30); }
      }
      &.mb-2 h3 { margin: 2rem 0; 
        @include breakpoint(medium) { margin-top: 4rem; }
      }
    }
  }

  &--simple { margin: rem-calc(100) auto;
    &#content { padding-top: 150px; margin-top: -50px; 
      @include breakpoint(medium) { padding-top: 100px; margin-top: 0; } 
    }
  }

  &--contact { margin: rem-calc(60) auto;
    &__card { position: relative; margin-top: -110px; padding: rem-calc(30 60) !important; border-radius: 10px; background-color: #FFF; box-shadow: 0px -5px 16px 4px rgba(0,0,0,.2);
      @include breakpoint(large) { margin-top: -460px; }
      &--title { margin-bottom: 2rem; font-size: rem-calc(25); font-weight: bold; }

      #urgent-checkbox {
        &::after { content: "Marque este campo caso precise de um retorno rápido."; display: block; margin-top: rem-calc(-8); margin-left: rem-calc(24); margin-bottom: rem-calc(32); line-height: 1.5; color: #666; }
      }
    }
    &__text { margin: rem-calc(40) auto; }
  }

} // close .section